import gql from 'graphql-tag';

export const couponCheck = gql`
  query couponCheck($coupon: String!, $items: [String]) {
    couponCheck(coupon: $coupon, items: $items) {
      _id
      coupon
      discount
      value
      productType
      accountCode
    }
  }
`;

export const getAreaName = gql`
  query getAreaName($id: Int!) {
    getAreaName(id: $id) {
      _id
      id
      nameEsFriendly
      nameEs
    }
  }
`;

export const getPrePublication = gql`
  query getPrePublication($product_type: String!, $area: Int) {
    getPrePublication(product_type: $product_type, area: $area) {
      _id
      id
      ean
      priceWithDiscount
      prices {
        sale
      }
      title
      titleFriendly
      authors {
        name
      }
      mainImg
    }
  }
`;
export const getImportantProduct = gql`
  query getImportantProduct($product_type: String!, $area: Int) {
    getImportantProduct(product_type: $product_type, area: $area) {
      _id
      id
      ean
      priceWithDiscount
      prices {
        sale
      }
      title
      titleFriendly
      authors {
        name
      }
      mainImg
    }
  }
`;

export const getAreasPredictive = gql`
  query getAreasPredictive($search: String!, $limit: Int!) {
    getAreasPredictive(search: $search, limit: $limit) {
      _id
      id
      nameEs
      nameEsFriendly
    }
  }
`;

export const checkStockItem = gql`
  query checkStockItem($items: [String]!) {
    checkStockItem(items: $items) {
      _id
      title
      ean
      priceWithDiscount
      titleFriendly
      lastBuyPrice
      exchangeRate
      buyExpenses
      mainImg
      reposition_level
      stock_available
      availability {
        OK
        msg
        status
      }
      dimensions {
        depth
        width
        height
      }
      reposition_level
      book {
        description
        table_of_contents
        authors {
          _id
          name
        }
        lang
        pages
        binding
        edition {
          number
          year
          month
        }
      }
      brand {
        id
        name
      }
      product_type
      authors {
        name
      }
      not_released
      stock_available
      unavailable
      prices {
        sale
      }
      vat {
        sale
      }
      group_property_names
      suscription {
        email
      }
      tags {
        items
        category
      }
      themes_text
      defaultVariant
      variantChildren {
        _id
        id
        title
        ean
        priceWithDiscount
        titleFriendly
        dimensions {
          depth
          width
          height
        }
        reposition_level
        book {
          authors {
            _id
            name
          }
          lang
          pages
          binding
          edition {
            number
            year
            month
          }
        }
        brand {
          id
          name
        }
        product_type
        authors {
          name
        }
        not_released
        stock_available
        unavailable
        prices {
          sale
        }
        vat {
          sale
        }
        variant {
          group_id
          properties {
            color
            finish
          }
        }
      }
    }
  }
`;

export const getAreas = gql`
  query getAreas {
    getAreas {
      _id
      id
      nameEs
      nameEsFriendly
    }
  }
`;

export const getOneTag = gql`
  query getTags($_id: String, $name: String) {
    getTags(_id: $_id, name: $name) {
      _id
      id
      tagEs
    }
  }
`;

export const getTags = gql`
  query getTags($search: String, $limit: Int) {
    getTags(search: $search, limit: $limit) {
      _id
      id
      tagEs
    }
  }
`;

export const currentProducts = gql`
  query currentProducts(
    $limit: Int
    $skip: Int!
    $sort: String
    $order: Int
    $product_type: [String]
    $lang: [String]
    $rangePrice: RangePrice
    $areas_of_interest: [Int]
    $areas_of_interest_text: String
    $tags: [TagsInput]
    $type: String
    $searchGeneral: String
    $prox: Boolean
    $area_prox: String
    $featured: Boolean
    $is_new: Boolean
  ) {
    books(
      limit: $limit
      skip: $skip
      sort: $sort
      order: $order
      product_type: $product_type
      lang: $lang
      rangePrice: $rangePrice
      areas_of_interest: $areas_of_interest
      areas_of_interest_text: $areas_of_interest_text
      tags: $tags
      type: $type
      searchGeneral: $searchGeneral
      prox: $prox
      area_prox: $area_prox
      featured: $featured
      is_new: $is_new
    ) {
      id
      _id
      titleFriendly
      title
      ean
      mainImg
      ebookContent
      relatedInfo {
        _id
        id
        product_type
        priceWithDiscount
        ean
        title
        titleFriendly
        ebookContent
        weight
        prices {
          sale
          clearing
          saleSpecialDiscount
          ssdFrom
          ssdTo
        }
        book {
          authors {
            _id
            name
          }
          pages
          lang
          binding
        }
        availability {
          OK
          msg
          status
        }
      }
      prices {
        sale
        clearing
        saleSpecialDiscount
        ssdFrom
        ssdTo
      }
      vat {
        sale
      }
      tags {
        category
        items
      }
      book {
        authors {
          _id
          name
        }
        lang
        binding
        edition {
          year
          number
          month
        }
      }
      product_type
      priority
      priority_nov
      priceWithDiscount
      # _id
      # id
      # ean
      # priceWithDiscount
      # title
      # mainImg
      # book {
      #   authors {
      #     _id
      #     name
      #   }
      # }
      # titleFriendly
      # areas_of_interest_text
      # areas_of_interest
      # product_type
      # reposition_level
      # stock_available
      not_released
      # availability {
      #   OK
      #   msg
      #   status
      # }
      # authors {
      #   name
      # }
      # prices {
      #   sale
      # }
      # vat {
      #   sale
      # }
    }
  }
`;

// export const currentProductsCount = gql`
//   query currentProducts(
//     $limit: Int
//     $skip: Int!
//     $sort: String
//     $order: Int
//     $product_type: [String]
//     $lang: [String]
//     $rangePrice: RangePrice
//     $areas_of_interest: [Int]
//     $tags: [TagsInput]
//     $type: String
//     $searchGeneral: String
//   ) {
//     books(
//       limit: $limit
//       skip: $skip
//       sort: $sort
//       order: $order
//       product_type: $product_type
//       lang: $lang
//       rangePrice: $rangePrice
//       areas_of_interest: $areas_of_interest
//       tags: $tags
//       type: $type
//       searchGeneral: $searchGeneral
//     ) {
//       _id
//       id
//     }
//   }
// `;

// export const currentProductsServer = gql`
//   query currentProducts(
//     $limit: Int
//     $skip: Int!
//     $sort: String
//     $order: Int
//     $product_type: [String]
//     $lang: [String]
//     $rangePrice: RangePrice
//     $areas_of_interest: [Int]
//     $tags: [TagsInput]
//     $type: String
//     $searchGeneral: String
//   ) {
//     books(
//       limit: $limit
//       skip: $skip
//       sort: $sort
//       order: $order
//       product_type: $product_type
//       lang: $lang
//       rangePrice: $rangePrice
//       areas_of_interest: $areas_of_interest
//       tags: $tags
//       type: $type
//       searchGeneral: $searchGeneral
//     ) {
//       id
//       ean
//       title
//       titleFriendly
//       mainImg
//       prices {
//         sale
//       }
//       # _id
//       # id
//       # mainImg
//       # ean
//       # priceWithDiscount
//       # titleFriendly
//       # title
//       # reposition_level
//       # stock_available
//       # availability {
//       #   OK
//       #   msg
//       #   status
//       # }
//       # prices {
//       #   sale
//       # }
//       # vat {
//       #   sale
//       # }
//       # suscription {
//       #   email
//       # }
//     }
//   }
// `;

export const aggregate = gql`
  query aggregate(
    $collectionName: String!
    $group: String!
    $total: String
    $product_type: [String]
    $lang: [String]
    $rangePrice: RangePrice
    $areas_of_interest: [Int]
    $tags: [TagsInput]
    $type: String
    $searchGeneral: String
    $languages: [String]
    $version: [String]
    $prox: Boolean
    $area_prox: String
  ) {
    aggregate(
      collectionName: $collectionName
      group: $group
      total: $total
      product_type: $product_type
      lang: $lang
      rangePrice: $rangePrice
      areas_of_interest: $areas_of_interest
      tags: $tags
      type: $type
      searchGeneral: $searchGeneral
      languages: $languages
      version: $version
      prox: $prox
      area_prox: $area_prox
    ) {
      _id
      results {
        _id
        name
        total
        category
      }
    }
  }
`;

// export const currentJournals = gql`
//   query currentJournals(
//     $limit: Int
//     $skip: Int!
//     $sort: String!
//     $order: Int!
//     $lang: [String]
//     $version: [String]
//     $type: String
//     $searchGeneral: String
//   ) {
//     journals(
//       limit: $limit
//       skip: $skip
//       sort: $sort
//       order: $order
//       lang: $lang
//       version: $version
//       type: $type
//       searchGeneral: $searchGeneral
//     ) {
//       _id
//       title
//       version
//       issn
//       issues
//       id_journal
//       country_key
//       brand {
//         id
//         name
//       }
//       languages
//       subjects
//       titleFriendly
//     }
//   }
// `;

export const me = gql`
  query me($state: Float) {
    me(state: $state) {
      _id
      email
      newsletterActive
      createdAt
      profile {
        alias
        firstName
        lastName
        mobile
        phone
        areaOfInterest
        NIF
        customerId
        newsletter {
          areaOfInterest
        }
        ebooks {
          _id
          ean
          code
          buyDate
          mainImg
        }
        basket {
          _id
          ean
          units
          book {
            lastBuyPrice
            exchangeRate
            buyExpenses
            priceWithDiscount
            product_type
            titleFriendly
            _id
            title
            mainImg
            brand {
              name
            }
          }
        }
        shopping_default
        shoppingAddress {
          name
          address
          route
          streetNumber
          floor
          postalCode
          locality
          isOutUE
          province
          country
          comment
          deliverTo
          countryCode
          location {
            lat
            lng
          }
          cif
          businessName
        }
        billing_default
        billingData {
          address
          route
          streetNumber
          floor
          postalCode
          locality
          province
          country
          countryCode
          cif
          businessName
        }
        couponsUsed {
          _id
          coupon
          discount
          value
          usedDate
        }
      }
      bestCoupon {
        _id
        coupon
        discount
        value
        productType
        accountCode
      }
    }
  }
`;

export const currentJournal = gql`
  query currentJournals($id_journal: Int) {
    journal(id_journal: $id_journal) {
      _id
      id_journal
      title
      version
      issn
      issues
      country_key
      titleFriendly
      brand {
        id
        name
      }
      languages
      version
      packs {
        _id
        id_pack
        rates {
          _id
          id_rate
          subscriber_type
          price
          start
          end
          valid_to
          is_valid
        }
        journals {
          _id
          id_journal
          title
          version
          issn
          issues
          country_key
          titleFriendly
        }
      }
    }
  }
`;

export const getBookId = gql`
  query getBookId($ean: String!) {
    book(ean: $ean) {
      _id
      id
    }
  }
`;

export const currentProduct = gql`
  query currentProduct($ean: String!) {
    book(ean: $ean) {
      user @client
      _id
      banner {
        active
        launching
      }
      prepublication {
        launching
        start
        end
      }
      id
      title
      ean
      titleFriendly
      mainImg
      priceWithDiscount
      ebookContent
      relatedInfo {
        _id
        id
        product_type
        priceWithDiscount
        ean
        title
        titleFriendly
        ebookContent
        weight
        allImg {
          link
          width
          height
        }
        mainImg
        book {
          authors {
            _id
            name
          }
          lang
          pages
          binding
        }
        prices {
          sale
          clearing
          saleSpecialDiscount
          ssdFrom
          ssdTo
        }
        availability {
          OK
          msg
          status
        }
        dimensions {
          depth
          width
          height
        }
      }
      attachments {
        fileName
        fileType
        caption
      }
      weight
      allImg {
        link
        width
        height
      }
      # reposition_level
      # stock_available
      availability {
        OK
        msg
        status
      }
      dimensions {
        depth
        width
        height
      }
      # reposition_level
      book {
        description
        table_of_contents
        authors {
          _id
          name
        }
        lang
        pages
        binding
        edition {
          number
          year
          month
        }
      }
      brand {
        id
        name
      }
      product_type
      authors {
        name
      }
      not_released
      stock_available
      isNotToPublish
      unavailable
      prices {
        sale
        saleSpecialDiscount
        ssdTo
        ssdFrom
      }
      vat {
        sale
      }
      # group_property_names
      suscription {
        email
      }
      tags {
        items
        category
      }
      themes_text
      variantProduct {
        ean
        title
        priceWithDiscount
        titleFriendly
      }
      variant {
        group_id
        properties {
          color
          finish
          cuff
        }
      }
      defaultVariant
      variantChildren {
        _id
        id
        title
        ean
        priceWithDiscount
        titleFriendly
        dimensions {
          depth
          width
          height
        }
        # reposition_level
        availability {
          OK
          msg
          status
        }
        book {
          table_of_contents
          authors {
            _id
            name
          }
          lang
          pages
          binding
          description
          binding
          edition {
            number
            year
            month
          }
        }
        brand {
          id
          name
        }
        product_type
        authors {
          name
        }
        not_released
        allImg {
          link
          width
          height
        }
        tags {
          items
          category
        }
        themes_text
        mainImg
        # stock_available
        unavailable
        prices {
          sale
        }
        vat {
          sale
        }
        variant {
          group_id
          properties {
            color
            finish
            cuff
          }
        }
      }
    }
  }
`;

export const currentProductID = gql`
  query currentProductID($id: String!) {
    book: bookByIdBBDD(id: $id) {
      _id
      id
      banner {
        active
        launching
      }
      ebookContent
      relatedInfo {
        _id
        id
        product_type
        priceWithDiscount
        ean
        title
        titleFriendly
        ebookContent
        weight
        prices {
          sale
          clearing
          saleSpecialDiscount
          ssdFrom
          ssdTo
        }
        availability {
          OK
          msg
          status
        }
        book {
          authors {
            _id
            name
          }
          pages
          lang
          binding
        }
      }
      prepublication {
        launching
        start
        end
      }
      title
      ean
      titleFriendly
      mainImg
      priceWithDiscount
      attachments {
        fileName
        fileType
        caption
      }
      weight
      allImg {
        link
        width
        height
      }
      # reposition_level
      # stock_available
      availability {
        OK
        msg
        status
      }
      dimensions {
        depth
        width
        height
      }
      # reposition_level
      book {
        description
        table_of_contents
        authors {
          _id
          name
        }
        lang
        pages
        binding
        edition {
          number
          year
          month
        }
      }
      brand {
        id
        name
      }
      product_type
      authors {
        name
      }
      not_released
      stock_available
      isNotToPublish
      unavailable
      prices {
        sale
        saleSpecialDiscount
        ssdTo
        ssdFrom
      }
      vat {
        sale
      }
      # group_property_names
      suscription {
        email
      }
      tags {
        items
        category
      }
      themes_text
      variantProduct {
        ean
        title
        priceWithDiscount
        titleFriendly
      }
      variant {
        group_id
        properties {
          color
          finish
          cuff
        }
      }
      defaultVariant
      variantChildren {
        _id
        id
        title
        ean
        priceWithDiscount
        titleFriendly
        dimensions {
          depth
          width
          height
        }

        availability {
          OK
          msg
          status
        }
        book {
          table_of_contents
          authors {
            _id
            name
          }
          lang
          pages
          binding
          description
          edition {
            number
            year
            month
          }
        }
        brand {
          id
          name
        }
        product_type
        authors {
          name
        }
        not_released
        allImg {
          link
          width
          height
        }
        tags {
          items
          category
        }
        themes_text
        mainImg

        unavailable
        prices {
          sale
        }
        vat {
          sale
        }
        variant {
          group_id
          properties {
            color
            finish
            cuff
          }
        }
      }
    }
  }
`;

export const getBasketUser = gql`
  query getUserBasket($idUser: ID!) {
    user(_id: $idUser) {
      _id
      profile {
        NIF
        basket {
          _id
          ean
          units
          book {
            _id
            id
            ean
            priceWithDiscount
            titleFriendly
            lastBuyPrice
            exchangeRate
            buyExpenses
            reposition_level
            product_type
            stock_available
            isNotToPublish
            availability {
              OK
              msg
              status
            }
            title
            weight
            dimensions {
              depth
              width
              height
            }
            tags {
              items
              category
            }
            brand {
              id
              name
            }
            prices {
              sale
              discount
              saleSpecialDiscount
              ssdFrom
              ssdTo
            }
            vat {
              sale
            }
            mainImg
          }
        }
      }
    }
  }
`;

export const getOrder = gql`
  query order($_id: ID, $order: String) {
    order(_id: $_id, order: $order) {
      _id
      order
      userId
      orderSequence
      amountIVA
      amountWithoutIVA
      deliveryAddress {
        name
        address
        route
        streetNumber
        floor
        postalCode
        locality
        province
        country
        comment
        isOutUE
        deliverTo
        countryCode
        cif
        businessName
      }
      transport
      amountTransport
      amount
      methodPayment
      fullNameUser
      fullNameUserClean
      legalNameFiscalData
      fiscalDoc
      state_order
      amountDiscount
      discountIdCode
      discountIdCodeWork
      confirmDate
      autorizationCode
      responseCode
      order_multiple
      giftCardCode
      errorCode
      deleted
      items {
        _id
        ean
        units
        title
        priceSignDeal
        priceSign
        itemState
        canceledDate
        book {
          priceWithDiscount
          lastBuyPrice
          exchangeRate
          buyExpenses
          _id
          id
          title
          reposition_level
          stock_available
          availability {
            OK
            msg
            status
          }
          prices {
            sale
          }
          vat {
            sale
          }
        }
      }
      confirmDate
      createdAt
    }
  }
`;

export const getOrdersUser = gql`
  query currentOrdersUser($userId: ID!) {
    orders(userId: $userId) {
      _id
      order
      userId
      orderSequence
      amountIVA
      otherTaxes {
        amount
        description
        name
      }
      amountWithoutIVA
      transport
      billGridId
      billId
      methodPayment
      deliveryAddress {
        name
        address
        route
        streetNumber
        floor
        postalCode
        locality
        province
        country
        comment
        deliverTo
        countryCode
        cif
        businessName
        isOutUE
      }
      fiscalAddress {
        name
        address
        locality
        cif
      }
      items {
        ean
        units
        priceSignDeal
        priceSign
        itemState
        canceledDate
        invoice {
          _id
          billGridId
          amountDiscount
          amountIVA
          discountIdCode
          billId
          orderSequence
          state_order
          order
          amountTransport
          couponObject {
            _id
            coupon
            discount
            value
            productType
          }
          otherTaxes {
            amount
            description
            name
          }
        }
        shipped
        book {
          vat {
            sale
          }
          ean
          priceWithDiscount
          titleFriendly
          id
          title
          reposition_level
          mainImg
          product_type
          stock_available
          availability {
            OK
            msg
            status
          }
          prices {
            clearing
            sale
          }
        }
      }
      amountTransport
      amount
      discountIdCode
      legalNameFiscalData
      state_order
      amountDiscount
      confirmDate
      autorizationCode
      responseCode
      order_multiple
      deleted
      confirmDate
      createdAt
      couponObject {
        _id
        coupon
        discount
        value
        productType
      }
    }
  }
`;

export const getMagazinesPredictive = gql`
  query getMagazinesPredictive($search: String!, $limit: Int!) {
    getMagazinesPredictive(search: $search, limit: $limit) {
      _id
      title
      version
      issn
      issues
      id_journal
      country_key
      brand {
        id
        name
      }
      languages
      subjects
      titleFriendly
    }
  }
`;

export const getTagSearchCustom = gql`
  query getTagsCustom(
    $search: String!
    $limit: Int
    $product_type: [String]
    $areaOfInterest: [Int]
  ) {
    getTagsCustom(
      search: $search
      limit: $limit
      product_type: $product_type
      areas_of_interest: $areaOfInterest
    ) {
      _id
      name
      total
    }
  }
`;

export const getResultsPredictive = gql`
  query getResultsPredictive(
    $search: String!
    $limit: Int!
    $areas_of_interest: [Int]
    $product_type: [String]
    $orderByScore: Boolean
  ) {
    books(
      search: $search
      limit: $limit
      areas_of_interest: $areas_of_interest
      order: -1
      product_type: $product_type
      orderByScore: $orderByScore
    ) {
      id
      ean
      titleFriendly
      tags {
        category
        items
      }
      ebookContent
      relatedInfo {
        _id
        id
        product_type
        priceWithDiscount
        ean
        title
        titleFriendly
        ebookContent
        weight
        availability {
          OK
          msg
          status
        }
        prices {
          sale
          clearing
          saleSpecialDiscount
          ssdFrom
          ssdTo
        }
        book {
          authors {
            _id
            name
          }

          lang
          pages
          binding
        }
      }
      product_type
      mainImg
      title
      priceWithDiscount
      prices {
        sale
      }
      book {
        authors {
          name
        }
        binding
        pages
        edition {
          year
        }
      }
    }
  }
`;

export const getTagsPredictive = gql`
  query getTagsPredictive(
    $search: String!
    $limit: Int!
    $areas_of_interest: [Int]
    $product_type: [String]
  ) {
    tags(
      searchTag: $search
      limit: $limit
      areas_of_interest: $areas_of_interest
      product_type: $product_type
    ) {
      _id
      name
      category
      tagEs
    }
  }
`;

export const getArea = gql`
  query getArea($nameEsFriendly: String!) {
    getArea(nameEsFriendly: $nameEsFriendly) {
      _id
      id
      nameEs
      nameEsFriendly
    }
  }
`;

export const getResultsPredictiveSearch = gql`
  query getResultsPredictiveSearch(
    $search: String!
    $limit: Int!
    $areas_of_interest: [Int]
    $product_type: [String]
  ) {
    resultsPredictive(
      searchTag: $search
      limit: $limit
      areas_of_interest: $areas_of_interest
      product_type: $product_type
    ) {
      _id
      name
      category
    }
  }
`;

export const shippingAPI = gql`
  query shippingAPI($amount: Float!, $province: String!, $country: String!, $zip: String) {
    shippingAPI(amount: $amount, province: $province, country: $country, zip: $zip) {
      company
      fee
      days
    }
  }
`;

export const productsWithBanners = gql`
  query productsWithBanners(
    $_id: ID
    $skip: Int
    $limit: Int
    $sort: String
    $order: Int
    $hasBanner: String
    $product_type: [String]
    $areas_of_interest: [Int]
    $unpublish: Boolean
  ) {
    productsWithBanners(
      _id: $_id
      skip: $skip
      limit: $limit
      sort: $sort
      order: $order
      hasBanner: $hasBanner
      product_type: $product_type
      areas_of_interest: $areas_of_interest
      unpublish: $unpublish
    ) {
      _id
      id
      title
      ean
      priceWithDiscount
      titleFriendly

      book {
        authors {
          _id
          name
        }
        binding
        edition {
          number
          year
          month
        }
      }

      product_type

      not_released

      prices {
        saleSpecialDiscount
        ssdFrom
        ssdTo
        sale
      }

      mainImg
      banner {
        active
        launching
        start
        end
        price
      }
    }
  }
`;

export const getBestSellers = gql`
  query getBestSellers($product_type: String, $areas_of_interest: [Int]) {
    getBestSellers(product_type: $product_type, areas_of_interest: $areas_of_interest) {
      id
      _id
      titleFriendly
      title
      ean
      mainImg
      areas_of_interest
      prices {
        sale
        clearing
        saleSpecialDiscount
        ssdFrom
        ssdTo
      }
      relatedInfo {
        _id
        id
        product_type
        priceWithDiscount
        ean
        title
        titleFriendly
        ebookContent
        weight
        book {
          pages
          authors {
            _id
            name
          }

          lang
          binding
        }
        availability {
          OK
          msg
          status
        }
        prices {
          sale
          clearing
          saleSpecialDiscount
          ssdFrom
          ssdTo
        }
      }
      book {
        authors {
          _id
          name
        }
        lang
        binding
      }
      product_type
      priceWithDiscount
    }
  }
`;

export const booksFeatured = gql`
  query booksFeatured {
    booksFeatured {
      id
      _id
      titleFriendly
      title
      ean
      mainImg
      ebookContent
      relatedInfo {
        _id
        id
        product_type
        priceWithDiscount
        ean
        title
        titleFriendly
        ebookContent
        weight
        book {
          authors {
            _id
            name
          }

          pages
          lang
          binding
        }
        prices {
          sale
          clearing
          saleSpecialDiscount
          ssdFrom
          ssdTo
        }
        availability {
          OK
          msg
          status
        }
      }
      prices {
        sale
        clearing
        saleSpecialDiscount
        ssdFrom
        ssdTo
      }
      book {
        authors {
          _id
          name
        }
        lang
      }
      product_type
      priceWithDiscount
    }
  }
`;

export const booksNews = gql`
  query booksNews {
    booksNews {
      id
      _id
      titleFriendly
      title
      ean
      mainImg
      ebookContent
      relatedInfo {
        _id
        id
        product_type
        priceWithDiscount
        ean
        title
        titleFriendly
        ebookContent
        weight
        book {
          authors {
            _id
            name
          }
          pages
          lang

          binding
        }
        prices {
          sale
          clearing
          saleSpecialDiscount
          ssdFrom
          ssdTo
        }
        availability {
          OK
          msg
          status
        }
      }
      prices {
        sale
        clearing
        saleSpecialDiscount
        ssdFrom
        ssdTo
      }
      book {
        authors {
          _id
          name
        }
        lang
      }
      product_type
      priceWithDiscount
    }
  }
`;

export const relatedProducts = gql`
  query relatedProducts($idUser: String, $eanProduct: String!) {
    relatedProducts(idUser: $idUser, eanProduct: $eanProduct) {
      id
      _id
      titleFriendly
      title
      ean
      mainImg
      ebookContent
      relatedInfo {
        _id
        id
        product_type
        priceWithDiscount
        ean
        title
        titleFriendly
        ebookContent
        weight
        book {
          authors {
            _id
            name
          }
          pages
          lang
          binding
        }
        prices {
          sale
          clearing
          saleSpecialDiscount
          ssdFrom
          ssdTo
        }
        availability {
          OK
          msg
          status
        }
      }
      prices {
        sale
        clearing
        saleSpecialDiscount
        ssdFrom
        ssdTo
      }
      book {
        authors {
          _id
          name
        }
        lang
      }
      product_type
      priceWithDiscount
      # _id
      # id
      # title
      # ean
      # priceWithDiscount
      # titleFriendly
      # dimensions {
      #   depth
      #   width
      #   height
      # }
      # reposition_level
      # book {
      #   authors {
      #     _id
      #     name
      #   }
      #   lang
      #   pages
      #   binding
      #   edition {
      #     number
      #     year
      #     month
      #   }
      # }
      # brand {
      #   id
      #   name
      # }
      # product_type
      # authors {
      #   name
      # }
      # not_released
      # stock_available
      # unavailable
      # prices {
      #   sale
      # }
      # vat {
      #   sale
      # }
      # mainImg
      # defaultVariant
      # variantChildren {
      #   id
      #   variant {
      #     group_id
      #     properties {
      #       color
      #       finish
      #     }
      #   }
      # }
    }
  }
`;

export const booksCount = gql`
  query totalProducts(
    $author: String
    $id: Int
    $title: String
    $_id: String
    $product_type: [String]
    $lang: [String]
    $rangePrice: RangePrice
    $areas_of_interest: [Int]
    $tags: [TagsInput]
    $search: String
    $type: String
    $searchGeneralBO: [String]
    $searchGeneral: String
  ) {
    totalProducts(
      author: $author
      id: $id
      title: $title
      _id: $_id
      product_type: $product_type
      lang: $lang
      rangePrice: $rangePrice
      areas_of_interest: $areas_of_interest
      tags: $tags
      search: $search
      type: $type
      searchGeneralBO: $searchGeneralBO
      searchGeneral: $searchGeneral
    )
  }
`;

export const getRecentViews = gql`
  query getRecentViews($idUser: String) {
    getRecentViews(idUser: $idUser) {
      id
      _id
      titleFriendly
      title
      ean
      mainImg
      prices {
        sale
        clearing
        saleSpecialDiscount
        ssdFrom
        ssdTo
      }
      book {
        authors {
          _id
          name
        }
        lang
      }
      product_type
      priceWithDiscount
    }
  }
`;

export const getRateRequest = gql`
  query getRateRequest(
    $_id: ID
    $destinationAddress: OrderDeliveryAddressNew
    $package: newPackage
    $totalAmount: Float
  ) {
    getRateRequest(
      idUser: $_id
      destinationAddress: $destinationAddress
      package: $package
      totalAmount: $totalAmount
    ) {
      currency
      amount
      deliveryTime
      error
    }
  }
`;

export const getNewBannersDocument = gql`
  query getNewBanners($limit: Int, $skip: Int) {
    getNewBanners(limit: $limit, skip: $skip) {
      _id
      imageUrl
      link
      active
    }
  }
`;

export const getAreasFromNewsletter = gql`
  query getAreasFromNewsletter($email: String) {
    getAreasFromNewsletter(email: $email) {
      id
    }
  }
`;

export const getUserAreas = gql`
  query getUserAreas($email: String) {
    getUserAreas(email: $email) {
      status
      areaOfInterest
    }
  }
`;
